import React from "react";
import Bearsted from "../../images/branch-awards/BPA_Bearsted_2023.png";
import Birchington from "../../images/branch-awards/BPA_Birchington_2022.png";
import Broadstairs from "../../images/branch-awards/BPA_Broadstairs_2023.png";
import Chatham from "../../images/branch-awards/BPA_Chatham_2022.png";
import Dover from "../../images/branch-awards/BPA_Dover_2023.png";
import Faversham from "../../images/branch-awards/BPA_Faversham_2023.png";
import Gravesend from "../../images/branch-awards/BPA_Gravesend_2023.png";
import Hythe from "../../images/branch-awards/BPA_Hythe_2023.png";
import KingsHill from "../../images/branch-awards/BPA_West_Malling_2023.png";
import Tonbridge from "../../images/branch-awards/BPA_Tonbridge_2022.png";
import Welling from "../../images/branch-awards/BPA_Welling_2023.png";
import Walderslade from "../../images/branch-awards/BPA_Walderslade_2023.png";
import Staplehurst from "../../images/branch-awards/BPA_Staplehurst_2023.png";


const branchAwardMap = {
    'bearsted': Bearsted,
    'birchington': Birchington,
    'broadstairs': Broadstairs,
    'chatham': Chatham,
    'dover': Dover,
    'faversham': Faversham,
    'gravesend': Gravesend,    
    'hythe': Hythe,
    'kings-hill': KingsHill,
    'staplehurst': Staplehurst,
    'tonbridge': Tonbridge,
    'welling': Welling,
    'walderslade': Walderslade
};

const BranchAwardComponent = ( props ) => {
    const imageUrl = branchAwardMap[props.slug];
    if (!imageUrl) {
        // If the slug doesn't match any key in the mapping, return null or another fallback
        return null;
    }
    return(
       <div className="branch-award">
           <img src={imageUrl} alt="" />
        </div>
    );
};

export default BranchAwardComponent;
